import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "gsmColumn", "quarter1", "quarter2", "quarter3", "quarter4" ]

  connect() {
    console.log("It's working from dashboard")
  }

  hideColumn() {
    this.gsmColumnTargets.forEach((el) => {
      el.classList.toggle('d-none');
    });
  }

  hideQuarter(event) {
    const quarter = event.target.dataset.quarter;
    this[`${quarter}Targets`].forEach((el) => {
      el.classList.toggle('d-none');
    });
  }
}