import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const initChartEuropePartner = () => {
if (document.getElementById('europe-collect-chart')) {
  var ctx = document.getElementById('europe-collect-chart').getContext('2d');
        var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            radius: 1,
            label: 'Europe by MNK ',
            data: JSON.parse(ctx.canvas.dataset.data),
            barThickness: 32,
            backgroundColor: '#9BB455',
            borderColor: '#9BB455',
            borderWidth: 3,
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Evolution de votre collecte MNK Europe + en €'
            },
            legend: {
              display: false,
          }

          },
          scales: {
            x: {
              grid:{
                display:false
              },
            },
            y: {
              ticks: {
                // callback: (value, index, values) => {
                //   return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
                // },
                stepSize: 20000,
              },
              suggestedMax: JSON.parse(ctx.canvas.dataset.data).sort((a, b) => a - b)[0] + 10000,
              suggestedMin: JSON.parse(ctx.canvas.dataset.data).sort((a, b) => a - b)[0] - 10000,
            },
          }
        },
      });
}
};


export { initChartEuropePartner };
