import $ from 'jquery';

const initDatatables = () => {
  $(document).ready(function() {
    $('#partner-table').DataTable(
      {
        paging: false,
        scrollY: 400,
        scrollX: 300,
        scrollCollapse: true,
        "order": [],
        "language": {
          "lengthMenu": "Nombre d'éléments par page _MENU_ ",
          "zeroRecords": "Aucun investissement correspondant",
          "info": " _END_ investissement(s)",
          "infoEmpty": "Aucun investissement correspondant",
          "infoFiltered": "(sur un total de _MAX_ investissements).",
          "search": "Recherche"
        },
    }
    );
    // $('#dividend-table').DataTable({
    //   "order": [],
    // "language": {
    //   "lengthMenu": "Nombre d'éléments par page _MENU_ ",
    //   "zeroRecords": "Aucun dividende correspondant",
    //   "info": " _END_ dividende(s)",
    //   "infoEmpty": "Aucun dividende correspondant",
    //   "infoFiltered": "(sur un total de _MAX_ dividendes).",
    //   "search": "Recherche"
    // },
    // });
    $('#super-partner-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#super-partner-table-document').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#all-dividends-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#dividends-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#company-partners-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun collaborateur correspondant",
        "info": " _END_ collaborateur(s)",
        "infoEmpty": "Aucun collaborateur correspondant",
        "infoFiltered": "(sur un total de _MAX_ collaborateurs).",
        "search": "Recherche"
      },
    });

    $('#company-investments-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun investissement correspondant",
        "info": " _END_ investissement(s)",
        "infoEmpty": "Aucun investissement correspondant",
        "infoFiltered": "(sur un total de _MAX_ investissements).",
        "search": "Recherche"
      },
    });

    $('#create-dividends-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#releve-com-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });

    $('#releve-com-reccu-table').DataTable({
      bPaginate: false,
      paging: false,
      scrollY: 400,
      scrollX: 300,
      scrollCollapse: true,
      "order": [],
      "language": {
        "lengthMenu": "Nombre d'éléments par page _MENU_ ",
        "zeroRecords": "Aucun élément correspondant",
        "info": " _END_ élément(s)",
        "infoEmpty": "Aucun élément correspondant",
        "infoFiltered": "(sur un total de _MAX_ éléments).",
        "search": "Recherche"
      },
    });
  });
}

export { initDatatables };
