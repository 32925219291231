import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fields" ]

  addFields(event) {
    event.preventDefault();

    let newFields = this.fieldsTarget.querySelector('.releve_detail_fields').cloneNode(true);
    let time = new Date().getTime();
    let regexp = new RegExp("\\[0\\]", 'g');

    newFields.innerHTML = newFields.innerHTML.replace(regexp, `[${time}]`);
    newFields.querySelectorAll('input').forEach(input => input.value = '');
    this.fieldsTarget.appendChild(newFields);
  }
}