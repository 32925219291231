import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const initChart = () => {
if (document.getElementById('myChart')) {
  var ctx = document.getElementById('myChart').getContext('2d');
        var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            radius: 1,
            label: 'Valeur liquidative de rachat',
            data: JSON.parse(ctx.canvas.dataset.data),
            barThickness: 32,
            backgroundColor: JSON.parse(ctx.canvas.dataset.color)[0],
            borderColor: JSON.parse(ctx.canvas.dataset.color)[0],
            borderWidth: 3,
          },
          {
            radius: 1,
            label: 'Valeur liquidative de souscription',
            data: JSON.parse(ctx.canvas.dataset.data1),
            barThickness: 32,
            backgroundColor: JSON.parse(ctx.canvas.dataset.color)[1],
            borderColor: JSON.parse(ctx.canvas.dataset.color)[1],
            borderWidth: 3,
          }]
        },
        options: {
          maintainAspectRatio: false,
          // responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Évolution de la valeur liquidative en €/part depuis le lancement du fonds',
              font: {
                family: "montserrat",
                size: 12,
              }
            },
            legend: {
              position: 'bottom',
              labels: {
                padding: 30,
                  // This more specific font property overrides the global property
                font: {
                    family: "montserrat",
                    size: 10,
                }
              }
            }
          },
          scales: {
            x: {
              grid:{
                display:false
              }
            },
            y: {
              ticks: {
                callback: (value, index, values) => {
                  return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
                },
                stepSize: 50,
              },
              suggestedMin: parseInt(ctx.canvas.dataset.inv, 10) - 300,
              suggestedMax: parseInt(ctx.canvas.dataset.inv, 10) + 100,
            },
          }
        },
      });
}

if (document.getElementById('myChart-mobile')) {
  var ctx = document.getElementById('myChart-mobile').getContext('2d');
        var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            radius: 1,
            label: 'Valeur liquidative de rachat',
            data: JSON.parse(ctx.canvas.dataset.data),
            barThickness: 32,
            backgroundColor: JSON.parse(ctx.canvas.dataset.color)[0],
            borderColor: JSON.parse(ctx.canvas.dataset.color)[0],
            borderWidth: 3,
          },
          {
            radius: 1,
            label: 'Valeur liquidative de souscription',
            data: JSON.parse(ctx.canvas.dataset.data1),
            barThickness: 32,
            backgroundColor: JSON.parse(ctx.canvas.dataset.color)[1],
            borderColor: JSON.parse(ctx.canvas.dataset.color)[1],
            borderWidth: 3,
          }]
        },
        options: {
          plugins: {
            legend: {
              labels: {
                  // This more specific font property overrides the global property
                  font: {
                      family: "montserrat",
                      size: 10,
                  }
              }
            }
          },
          scales: {
            x: {
              grid:{
                display:false
              }
            },
            y: {
              ticks: {
                callback: (value, index, values) => {
                  return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
                },
                stepSize: 50,
              },
              suggestedMin: parseInt(ctx.canvas.dataset.inv, 10) - 300,
              suggestedMax: parseInt(ctx.canvas.dataset.inv, 10) + 100,
            },
          }
        },
      });
}
};



export { initChart };
