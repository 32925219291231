import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "investSourceForm", "autreFormInvestSource", "typeDeDemembrement", "demembrement"]

  connect() {
    this.checkAutreCheckboxOnLoad();
    this.checkAllCheckboxes();
    const autreCheckBoxInvestSource = this.investSourceFormTargets.at(-1)
    autreCheckBoxInvestSource.onclick = (event) => {
      if (autreCheckBoxInvestSource.checked) {
        this.autreFormInvestSourceTarget.classList.remove('d-none');
      } else {
        this.autreFormInvestSourceTarget.classList.add('d-none');
      }
    };
  }

  checkAutreCheckboxOnLoad() {
    const investmentSource = this.investSourceFormTargets.at(-1);
    if (investmentSource.checked) {
      this.autreFormInvestSourceTarget.classList.remove('d-none');
    } else {
      this.autreFormInvestSourceTarget.classList.add('d-none');
    }
  }

  checkAllCheckboxes() {
    const investmentSourceDiv = document.getElementById('investment-source');
    const investmentSource = investmentSourceDiv.getAttribute('data-investment-source');
    if (investmentSource) {
      const investmentSourceValues = investmentSource.split(', ');
      this.investSourceFormTargets.forEach((checkbox) => {
        if (investmentSourceValues.includes(checkbox.value)) {
          checkbox.checked = true;
        }
      });
    }
  }


  toggleType() {
    const shouldShow = this.demembrementTarget.checked;
    this.typeDeDemembrementTarget.classList.toggle("d-none", !shouldShow);
  }
}