import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
  "addressForm", "secondinvestorCategory",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "autreSituationPro", "autreFormSituationPro", "netWorthSource", "autreFormNetWorth",
  "noNifForm1", "btnAddNIF1", "anotherNifForm1", "noNifForm2", "btnAddNIF2", "anotherNifForm2", "noNifForm3"
  ]

  connect() {
    // Below is the method to display the input for "Autre" Form for "Situation Professionnelle"
    // in case the user chooses "Autre"
    // const situationProSelect = this.autreSituationProTarget
    // situationProSelect.onchange = (event) => {
    //   if (situationProSelect.value === "Autre") {
    //     this.autreFormSituationProTarget.classList.remove('d-none');
    //   } else {
    //     this.autreFormSituationProTarget.classList.add('d-none');
    //   }
    // };
  };
}
