import ahoy from "ahoy.js";

const initDividendsTracker = () => {
  const dividend_button =  document.querySelector('.dividend-confirmation')
  if (dividend_button) {
    dividend_button.addEventListener("click", e => {
        console.log(e.target)
        ahoy.track("check-dividend", e.target.dataset);
      });
  }
};

export { initDividendsTracker };