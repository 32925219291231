import { Controller } from "stimulus"

export default class extends Controller {
  updateLabel(event) {
    let input = event.target;
    let label = input.nextElementSibling;
    let file = input.files[0]; // Changed from fileName to file
    
    if (file.size > 5000000) { // 5MB
      label.classList.add("error");
      label.innerHTML = "Le document dépasse les 5MB";
    } else {
      let fileName = file.name;
      label.classList.add("selected");
      label.innerHTML = `<i class="fas fa-cloud-upload-alt"></i> ${fileName}`;
    }
  }
}