// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Add DataTables jQuery plugin
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@hotwired/turbo-rails"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
// import $ from 'jquery';
// global.$ = jQuery;


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------
import { initMapbox } from '../plugins/init_mapbox';
import { initChart } from '../plugins/init_chart'
import { initChartInvestmentValue } from "../plugins/init_chart_investment_value"
import { initChartDividendPayment } from "../plugins/init_chart_dividend_payment"
import { initDatatables } from "../plugins/init_datatables"
import { initPhotoPreview } from "../plugins/init_photo_preview"
import { initToggle } from "../plugins/init_toggle"
import { initSpinnerBs } from "../plugins/init_spinner_bs"
import { initChartMnkOnePartner } from "../plugins/init_chart_mnk_one_partner"
import { initChartPolskaPartner } from "../plugins/init_chart_polska_partner"
import { initChartEuropePartner } from "../plugins/init_chart_europe_partner"
import { initChartGlobalCorePartner } from "../plugins/init_chart_global_core_partner"
import { initChartSumInvestment } from "../plugins/init_chart_sum_investment"
import { initAllInvestmentChart } from "../plugins/init_all_investment_chart"
import { initAssetChart } from "../plugins/init_asset_chart"
import { initDividendsTracker } from "../events/dividend"
// External imports
import "bootstrap";
// Load datatables styles
import 'datatables.net-dt'
import 'datatables.net'
import ahoy from "ahoy.js"
import "chartkick/chart.js"
import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.min.css"
// import gtag from "src/analytics"
flatpickr(".datepicker", {})
flatpickr.localize(French); 
// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbo:load', () => {
  // Call your functions here, e.g:
  // initSelect2();
  initMapbox();
  initChart();
  initChartInvestmentValue();
  initChartDividendPayment();
  initDatatables();
  initPhotoPreview();
  initToggle();
  // initSpinnerBs();
  initChartMnkOnePartner();
  initChartPolskaPartner();
  initChartEuropePartner();
  initChartGlobalCorePartner();
  initAssetChart();
  initDividendsTracker();
  initChartSumInvestment();
  // initActiveNavbar();
  initAllInvestmentChart();
});

document.addEventListener('turbo:load', () => {
  $(function () {
    $('[data-toggle="popover"]').popover();
    $('.popover-dismiss').popover({
      trigger: 'focus'
    });
  });
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });
});

import "controllers"
