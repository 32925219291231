import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

const renderData = (ctx) => {
  const hashes = [
    {
        radius: 0,
        label: "MNK One",
        data: JSON.parse(ctx.data),
        backgroundColor: '#B78D0C',
        borderColor: '#B78D0C',
        legend: ctx.legendone
    },
    {
      radius: 0,
      label: "Polska by MNK",
      data: JSON.parse(ctx.datapolska),
      backgroundColor: '#D59769',
      borderColor: '#D59769',
      legend: ctx.legendpolska
    },
    {
      radius: 0,
      label: "Europe +",
      data: JSON.parse(ctx.dataeurope),
      backgroundColor: '#9BB455',
      borderColor: '#9BB455',
      legend: ctx.legendeurope
    },
    {
      radius: 0,
      label: "MNK Global Core",
      data: JSON.parse(ctx.dataglobal),
      backgroundColor: '#2B4746',
      borderColor: '#2B4746',
      legend: ctx.legendglobal
    }
  ];

  let dataArray = [{}, {}, {}, {}];
  hashes.forEach(hash => {
    if (hash.legend === "true") {
      const order = JSON.parse(ctx.order);
      order.forEach((item, index) => {
        if (item && hash.label === item[0]) {
          dataArray[index] = hash;
        }
      });
    }
  });
  dataArray = dataArray.filter(value => Object.keys(value).length !== 0)
  return {
    labels: JSON.parse(ctx.labels),
    datasets: dataArray,
  };
}
// const renderData = (ctx) => {

//   const mnkoneHash = {
//       radius: 0,
//       label: "MNKONE",
//       data: JSON.parse(ctx.data),
//       backgroundColor: '#B78D0C',
//       borderColor: '#B78D0C',
//   };

//   const polskaHash = {
//     radius: 0,
//     label: "Polska by MNK",
//     data: JSON.parse(ctx.datapolska),
//     backgroundColor: '#D59769',
//     borderColor: '#D59769',
//   };

//   const euroHash = {
//     radius: 0,
//     label: "Europe +",
//     data: JSON.parse(ctx.dataeurope),
//     backgroundColor: '#9BB455',
//     borderColor: '#9BB455',
//   };

//   let dataArray = [];
//   if (JSON.parse(ctx.order)[0][0] === 'MNK One' && JSON.parse(ctx.order)[1][0] === 'Polska by MNK') {

//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };

//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };

//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };
//   } else if (JSON.parse(ctx.order)[0][0] === 'MNK One' && JSON.parse(ctx.order)[1][0] === 'Europe +') {
//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };

//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };

//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };
//   } else if (JSON.parse(ctx.order)[0][0] === 'Polska by MNK' && JSON.parse(ctx.order)[1][0] === 'MNK One') {
//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };

//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };

//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };
//   } else if (JSON.parse(ctx.order)[0][0] === 'Polska by MNK' && JSON.parse(ctx.order)[1][0] === 'Europe +') {
//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };

//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };

//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };
//   } else if (JSON.parse(ctx.order)[0][0] === 'Europe +' && JSON.parse(ctx.order)[1][0] === 'Polska by MNK') {

//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };

//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };

//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };
//   } else if (JSON.parse(ctx.order)[0][0] === 'Europe +' && JSON.parse(ctx.order)[1][0] === 'MNK One') {
//     if (ctx.legendeurope === "true") {
//       dataArray.push(euroHash);
//     };

//     if (ctx.legendone === "true") {
//       dataArray.push(mnkoneHash);
//     };

//     if (ctx.legendpolska === "true") {
//       dataArray.push(polskaHash);
//     };
//   }
//   return {
//     labels: JSON.parse(ctx.labels),
//     datasets: dataArray,
//   };
// }

const initChartSumInvestment = () => {
if (document.getElementById('investment-chart')) {
  var ctx = document.getElementById('investment-chart').getContext('2d');
    var myChart = new Chart(ctx, {
    type: 'line',
    data: renderData(ctx.canvas.dataset),
    options: {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Montant de mes souscriptions en €',
        },
        legend: {
            position: 'bottom',
            labels: {
                // This more specific font property overrides the global property
                font: {
                    family: "montserrat",
                    size: 10,
                }
            }
        },
        stacked100: { enable: true } // Enable the stacked100 plugin
      },
      scales: {
        x: {
          grid:{
            display: false
          },
          stacked: true,
        },
        y: {
          ticks: {
            callback: (value, index, values) => {
              return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
            },
          },
          stacked: true,
          suggestedMin: 0,
          suggestedMax: parseInt(ctx.canvas.dataset.legendscale, 10) + (parseInt(ctx.canvas.dataset.legendscale, 10) / 3),
        }
      }
    },
    });
}
};



export { initChartSumInvestment };
