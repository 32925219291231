import { Controller } from "stimulus";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class extends Controller {

  static targets = ["chart"];

  connect() {
    this.renderChart();
  }

  renderChart() {
    const navsDataString = this.data.get("navs");
    if (navsDataString) {
      const navsData = JSON.parse(navsDataString);
      // datesHash should if navsDataString has more then 12 navs, group by quarter if less group by month
      const datesHash = navsData.length > 12 ? this.groupByQuarter(navsData) : this.groupByDate(navsData);
      const fund_id = navsData[0].fund_id;
      // const datesHash = this.groupByDate(navsData);
      const navTypes = this.extractUniqueNavTypes(navsData);
      const datasets = this.createDatasets(datesHash, navTypes, fund_id);
      const datasetsSouscription = this.createDatasetsSouscription(datesHash, navTypes, fund_id);
      var ctx = document.getElementById('navChart').getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: Object.keys(datesHash),
          datasets: [...datasets, ...datasetsSouscription]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                  // This more specific font property overrides the global property
                  font: {
                      family: "montserrat",
                      size: 10,
                  }
              }
            }
          },
          scales: {
            x: {
              grid:{
                display:false
              }
            },
            y: {
              ticks: {
                stepSize: 50,
              }
            },
          }
        },
        // options: {
        //   plugins: {
        //     title: {
        //       display: true,
        //       text: 'Montant de mes souscriptions en €',
        //     },
        //     legend: {
        //         position: 'bottom',
        //         labels: {
        //             // This more specific font property overrides the global property
        //             font: {
        //                 family: "montserrat",
        //                 size: 10,
        //             }
        //         }
        //     },
        //     stacked100: {
        //       enable: true,
        //       replaceTooltipLabel: false
        //     }
        //   }
        // }
      });
    } else {
      console.error("Data attribute 'navs' is nil or not present.");
    }
  }

  groupByDate(navsData) {
    return navsData.reduce((acc, nav) => {

      const date = nav.date;
      const dateObject = new Date(date);
      const formattedDate = dateObject.toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' });

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ class_type: nav.class_type, value: nav.value, nav_souscription: nav.nav_souscription });
      return acc;
    }, {});
  }

  groupByQuarter(navsData) {
    return navsData.reduce((acc, nav) => {
      const navDateMonth = new Date(nav.date).getMonth() + 1;
      const monthsToFilter = [3, 6, 9, 12];
      if (!monthsToFilter.includes(navDateMonth)) {
        // Skip or return early if nav.date's month is not in monthsToFilter
        return acc; // Or use 'continue' if inside a loop
      }

      const date = nav.date;
      const dateObject = new Date(date);
      const formattedDate = dateObject.toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' });
      console.log(formattedDate)
      // const quarter = Math.floor(dateObject.getMonth() / 3) + 1;
      // const formattedDate = `${dateObject.getFullYear()} Q${quarter}`;


      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({ class_type: nav.class_type, value: nav.value, nav_souscription: nav.nav_souscription });
      return acc;
    }, {});
  }
  extractUniqueNavTypes(navsData) {
    // Extract unique nav types from the data
    return [...new Set(navsData.map(nav => nav.class_type))];
  }

  createDatasets(datesHash, navTypes, fund_id) {
    const datasets = navTypes.map((navType) => {
      const dataPoints = this.createDataPoints(datesHash, navType);
      const colors = this.getFundColor(fund_id, navType);
      return {
        label: `VL de rachat ${navType}`,
        data: dataPoints,
        borderColor: colors[0],
        borderWidth: 1,
        fill: false,
        barThickness: 32,
        borderWidth: 3,
      };
    });
  
    return datasets;
  }

  createDatasetsSouscription(datesHash, navTypes, fund_id) {
    const datasets = navTypes.map((navType) => {
      const dataPoints = this.createDataPointsSouscription(datesHash, navType);
      const colors = this.getFundColor(fund_id, navType);

      return {
        label: `VL de souscription ${navType}`,
        data: dataPoints,
        borderColor: colors[1],
        borderWidth: 1,
        fill: false,
        barThickness: 32,
        borderWidth: 3,
      };
    });
  
    return datasets;
  }

  createDataPoints(datesHash, navType) {
    return Object.keys(datesHash).map(date => {
      const navsOnDate = datesHash[date];
      const navOfType = navsOnDate.find(nav => nav.class_type === navType); 
      return navOfType ? navOfType.value : null;
    });
  }

  createDataPointsSouscription(datesHash, navType) {
    return Object.keys(datesHash).map(date => {
      const navsOnDate = datesHash[date];
      const navOfType = navsOnDate.find(nav => nav.class_type === navType); 
      return navOfType ? navOfType.nav_souscription : null;
    });
  }

  getFundColor(fund_id, class_type) {
    // Generate a random color for dataset border

    switch (fund_id) {
      case 1:
        return ["rgb(183, 141, 12)", "rgb(29, 157, 217)"];
      case 2:
        return this.getColorsForFund2(class_type, ["#01aea2", "#d92147"]);
      case 3:
        return this.getColorsForFund3(class_type, ["#f79800", "#844848"]);
    }
  }

  getColorsForFund2(class_type, colors_array) {
    // Define colors based on class_type for fund_id 1
    // Modify this based on your specific requirements
    if (class_type === "A") {
      return colors_array;
    } else if (class_type === "B") {
      return ["#76a18e", "#d92147"]
    } else if (class_type === "C") {
      return ["#01aea2", "#b722d8"]; // Example colors for class_type B
    } else if (class_type === "Global") {
      return ["#01ad5d", "#8f22d8"]; // Example colors for class_type B
    }
  }

  getColorsForFund3(class_type, colors_array) {
    // Define colors based on class_type for fund_id 1
    // Modify this based on your specific requirements
    if (class_type === "A") {
      return colors_array;
    } else if (class_type === "B") {
      return ["#f4dc00", "#76a098"]; // Example colors for class_type B
    } else if (class_type === "C") {
      return ["#ff4800", "#749c9e"];
    } else if (class_type === "D") {
      return ["#ff9900", "#773333"]; // Example colors for class_type B
    } else if (class_type === "D") {
      return ["#ffd600", "#804545"]; // Example colors for class_type B
    } else if (class_type === "E") {
      return ["#ffeb00", "#844b4b"]; // Example colors for class_type B
    } else if (class_type === "F") {
      return ["#ffff00", "#885151"]; // Example colors for class_type B
    } else if (class_type === "Global") {
      return ["#f44100", "#7f7146"]; // Example colors for class_type B
    }
  }
}

// renderChart() {
//   const navsData = JSON.parse(this.data.get("navs"));
//   const navType = this.data.get("type");
//   debugger
//   const filteredNavs = navsData.filter(nav => nav.class_type === navType);

//   const navAmounts = filteredNavs.map(nav => nav.nav_souscription);
//   const navLabels = filteredNavs.map(nav => nav.date);

//   const ctx = document.getElementById('navChart').getContext('2d');
//   new Chart(ctx, {
//     type: 'line',
//     data: {
//       labels: navLabels,
//       datasets: [{
//         label: `Nav Amount (${navType})`,
//         data: navAmounts,
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//         fill: false
//       }]
//     }
//   });
// }