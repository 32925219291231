// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "chevronDown1","chevronDown2", "chevronDown3", "chevronLeft1", "chevronLeft2", "chevronLeft3" ]

  connect() {
    // console.log("It's working")
  }

  showFAQ1() {
    this.chevronDown1Target.classList.toggle("d-none");
    this.chevronLeft1Target.classList.toggle("d-none");
  }
  showFAQ2() {
    this.chevronDown2Target.classList.toggle("d-none");
    this.chevronLeft2Target.classList.toggle("d-none");
  }
  showFAQ3() {
    this.chevronDown3Target.classList.toggle("d-none");
    this.chevronLeft3Target.classList.toggle("d-none");
  }
}
