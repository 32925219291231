// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "table", "handswipe", "handswipe2", "chevronDown1","chevronDown2", "chevronDown3", "chevronLeft1", "chevronLeft2", "chevronLeft3",
  "months2019", "months2020", "months2021", "months2022", "months2023", "months2024", "year2019", "year2020", "year2021", "year2022", "year2023", "year2024" ]

  range(start, end) {
    /* generate a range : [start, start+1, ..., end-1, end] */
    var len = end - start + 1;
    var a = new Array(len);
    for (let i = 0; i < len; i++) a[i] = start + i;
    return a;
  };

  connect() {
  // Method Below to enable HandSwipe on the Relevé des Commissions Table
    const handswipe = this.handswipeTarget;
    const handswipe2 = this.handswipe2Target;
    this.tableTarget.onmousewheel = function() {
      if (this.getBoundingClientRect()["x"] < -100) {
        handswipe.classList.add("d-none");
      } else {
        handswipe.classList.remove("d-none");
      }
    }

  /////////////////////////////////////////////////////////////////////////////
    const years = this.range(2019, new Date().getFullYear())
      years.forEach( year => {
        // Method Below to disable the click on the months that have no relevés de commission upfront
          const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          months.forEach(month => {
            const CloudDiv = document.getElementById('cloud' + month + year)
            if (!CloudDiv) {
              document.getElementById(month + year).classList.replace('upfront-months', 'upfront-months-none');
            }
          });

        // Method Below to disable the click on the years that have no relevés de commission récurrente
          if (!(document.getElementById("cloudrecurrentYear" + year))) {
            document.getElementById("recurrentYear" + year).classList.replace("recurrent-years", "recurrent-years-none");
          };

        // Method Below to enable HandSwipe on the Relevé de Commission Upfront Table
          let yearTable = year + "Table";
          let januaryDiv = "January" + year;
          let divTable = document.getElementById(yearTable)
          let januaryDivHTML = document.getElementById(januaryDiv)
          divTable.onmousewheel = function() {
            if (januaryDivHTML.getBoundingClientRect()["x"] < -100) {
              handswipe2.classList.add("d-none");
            } else {
              handswipe2.classList.remove("d-none");
            }
          }
      });
  }

  showFAQ1() {
    this.chevronDown1Target.classList.toggle("d-none");
    this.chevronLeft1Target.classList.toggle("d-none");
  }
  showFAQ2() {
    this.chevronDown2Target.classList.toggle("d-none");
    this.chevronLeft2Target.classList.toggle("d-none");
  }
  showFAQ3() {
    this.chevronDown3Target.classList.toggle("d-none");
    this.chevronLeft3Target.classList.toggle("d-none");
  }

  showFAQ1topElement() {
    this.chevronDown1Target.classList.toggle("d-none");
    this.chevronLeft1Target.classList.toggle("d-none");
  }
  showFAQ2topElement() {
    this.chevronDown2Target.classList.toggle("d-none");
    this.chevronLeft2Target.classList.toggle("d-none");
    if (this.chevronDown2Target.parentElement.parentElement.classList.contains("document-element-top-commission-opened")) {
      setTimeout(() => {
        this.chevronDown2Target.parentElement.parentElement.classList.toggle("document-element-top-commission");
        this.chevronDown2Target.parentElement.parentElement.classList.toggle("document-element-top-commission-opened");
      }, 310);
    } else {
      this.chevronDown2Target.parentElement.parentElement.classList.toggle("document-element-top-commission");
      this.chevronDown2Target.parentElement.parentElement.classList.toggle("document-element-top-commission-opened");
    };
  }
  showFAQ3topElement() {
    this.chevronDown3Target.classList.toggle("d-none");
    this.chevronLeft3Target.classList.toggle("d-none");
    if (this.chevronDown3Target.parentElement.parentElement.classList.contains("document-element-top-commission-opened")) {
      setTimeout(() => {
        this.chevronDown3Target.parentElement.parentElement.classList.toggle("document-element-top-commission");
        this.chevronDown3Target.parentElement.parentElement.classList.toggle("document-element-top-commission-opened");
      }, 310);
    } else {
      this.chevronDown3Target.parentElement.parentElement.classList.toggle("document-element-top-commission");
      this.chevronDown3Target.parentElement.parentElement.classList.toggle("document-element-top-commission-opened");
    };
  }
  showMonths2019() {
    if (this.months2019Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-none", "d-flex");
      this.year2019Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }
  showMonths2020() {
    if (this.months2020Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2020Target.classList.replace("d-none", "d-flex");
      this.year2020Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }
  showMonths2021() {
    if (this.months2021Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2021Target.classList.replace("d-none", "d-flex");
      this.year2021Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }
  showMonths2022() {
    if (this.months2022Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2022Target.classList.replace("d-none", "d-flex");
      this.year2022Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }

  showMonths2023() {
    if (this.months2023Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2023Target.classList.replace("d-none", "d-flex");
      this.year2023Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }

  showMonths2024() {
    if (this.months2024Target.classList.contains("d-none")) {
      this.months2019Target.classList.replace("d-flex", "d-none");
      this.year2019Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2020Target.classList.replace("d-flex", "d-none");
      this.year2020Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2021Target.classList.replace("d-flex", "d-none");
      this.year2021Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2022Target.classList.replace("d-flex", "d-none");
      this.year2022Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2023Target.classList.replace("d-flex", "d-none");
      this.year2023Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.months2024Target.classList.replace("d-none", "d-flex");
      this.year2024Target.classList.replace("upfront-years", "clicked-upfront-years");
      this.handswipe2Target.classList.remove("d-none");
    } else {
      this.months2024Target.classList.replace("d-flex", "d-none");
      this.year2024Target.classList.replace("clicked-upfront-years", "upfront-years");
      this.handswipe2Target.classList.add("d-none");
    }
  }

  //Method Below to show the Cloud Download for the Relevé de Commission Upfront Table
    showDownload() {
      const monthId = window.event.srcElement.id;
      const cloudId = 'cloud' + monthId;
      const cloudDiv =  document.getElementById(cloudId);
      const monthDiv = document.getElementById(monthId);
      if (cloudDiv) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const years = this.range(2019, new Date().getFullYear())

        if (cloudDiv.classList.contains("d-none")) {
          years.forEach( year => {
            months.forEach(month => {
              const otherCloudDiv = document.getElementById('cloud' + month + year)
              if (otherCloudDiv) {
                otherCloudDiv.classList.add('d-none');
                document.getElementById(month + year).classList.remove('clicked-upfront-months');
              };
            });
          });
          cloudDiv.classList.remove('d-none');
          monthDiv.classList.add('clicked-upfront-months');
        } else {
          cloudDiv.classList.add('d-none');
          monthDiv.classList.remove('clicked-upfront-months');
        };
      };
    };

  //Method Below to show the Cloud Download for the Relevé de Commission récurrente Table
    showRecurrentDownload() {
      const yearId = window.event.srcElement.id;
      const releveId = "cloud" + yearId;
      const years = this.range(2019, new Date().getFullYear());
      const releveDiv = document.getElementById(releveId);
      if (releveDiv) {
        const releveClass = document.getElementById(releveId).classList;
        const yearClass = document.getElementById(yearId).classList;

        if ( releveClass.contains('d-none')) {
          years.forEach( year => {
            document.getElementById('recurrentYear' + year).classList.remove('clicked-recurrent-years');
            if (document.getElementById('cloudrecurrentYear' + year)) {
              document.getElementById('cloudrecurrentYear' + year).classList.add('d-none');
            };
          });
          yearClass.toggle('clicked-recurrent-years');
          releveClass.toggle('d-none');
        } else {
          yearClass.toggle('clicked-recurrent-years');
          releveClass.toggle('d-none');
        };
      };
    };
}
