import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["Regbtn", "regForm", "tierceForm", "additionalFieldsContainer"]

  connect() {
    this.showReg();
    // this.showtierceForm();
  }

  showReg() {
    if (this.RegbtnTarget.checked === true) {
      this.regFormTarget.classList.remove("d-none");
    } else {
      this.regFormTarget.classList.add("d-none");
    }
  }

  // showtierceForm() {
  //   this.tierceFormTarget.classList.toggle("d-none");
  // }
}