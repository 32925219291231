const initToggle = () => {
  var chevron = document.getElementById("chevron-button");
  if (chevron) {
    chevron.addEventListener('click', (e)=>{
      e.preventDefault
      var answer = document.getElementById("faq-answer")
      if (answer.style.display === 'none') {
        answer.style.display = 'block'
      } else {
        answer.style.display = 'none'
      }
    })
  }
  var chevron1 = document.getElementById("chevron-button-1");
  if (chevron1) {
    chevron1.addEventListener('click', (e)=>{
      e.preventDefault
      var answer1 = document.getElementById("faq-answer-1")
      if (answer1.style.display === 'none') {
        answer1.style.display = 'block'
      } else {
        answer1.style.display = 'none'
      }
    })
  }
  var chevron2 = document.getElementById("chevron-button-2");
  if (chevron2) {
    chevron2.addEventListener('click', (e)=>{
      e.preventDefault
      var answer2 = document.getElementById("faq-answer-2")
      if (answer2.style.display === 'none') {
        answer2.style.display = 'block'
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        answer2.style.display = 'none'
        window.scrollTo(0, document.body.scrollHeight);
      }
    })
  }
}

export { initToggle };
