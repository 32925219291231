import Chart from 'chart.js/auto';

const initChartDividendPayment = () => {
  if (document.getElementById('dividend-chart')) {
    var ctx = document.getElementById('dividend-chart').getContext('2d');
      var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          radius: 1,
          label: 'MNK One',
          data: JSON.parse(ctx.canvas.dataset.dataone),
          barThickness: 22,
          backgroundColor: '#B78D0C',
          borderColor: '#B78D0C',
          borderWidth: 1
        },
        {
          radius: 1,
          label: 'Polska by MNK',
          data: JSON.parse(ctx.canvas.dataset.datapolska),
          barThickness: 22,
          backgroundColor: '#D59769',
          borderColor: '#D59769',
          borderWidth: 1
        },
        {
          radius: 1,
          label: 'Europe+',
          data: JSON.parse(ctx.canvas.dataset.dataeurope),
          barThickness: 22,
          backgroundColor: '#9BB455',
          borderColor: '#9BB455',
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Montant des dividendes sur l'année en €",
          },
          legend: {
            position: 'bottom',
            labels: {
                // This more specific font property overrides the global property
                font: {
                    family: "montserrat",
                    size: 10,
                }
            }
          }
        },
        scales: {
          x: {
            grid:{
              display:false
            },
            stacked: true,
          },
          y: {
            ticks: {
              callback: (value, index, values) => {
                return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
              },
            },
            stacked: true,
            suggestedMin: 0,
            suggestedMax: parseInt(ctx.canvas.dataset.maxvalue, 10) + (parseInt(ctx.canvas.dataset.maxvalue, 10) / 2),
          }
        }
      },
      });
  }
};



export { initChartDividendPayment };
