import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

const initAllInvestmentChart = () => {
  if (document.getElementById('investments-chart')) {
    var ctx = document.getElementById('investments-chart').getContext('2d');
      var myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          radius: 0,
          label: "MNKONE",  
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: '#B78D0C',
          borderColor: '#B78D0C',
        },
        {
          radius: 0,
          label: "Polska by MNK",  
          data: JSON.parse(ctx.canvas.dataset.datapolska),
          backgroundColor: '#D59769',
          borderColor: '#D59769',
        },
        {
          radius: 0,
          label: "Europe +",  
          data: JSON.parse(ctx.canvas.dataset.dataeurope),
          backgroundColor: '#9BB455',
          borderColor: '#9BB455',
        }
      ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Montant de mes souscriptions en €',
          },
          legend: {
              position: 'bottom',
              labels: {
                  // This more specific font property overrides the global property
                  font: {
                      family: "montserrat",
                      size: 10,
                  }
              }
          },
              stacked100: { enable: true } // Enable the stacked100 plugin
        },
        scales: {
          x: {
            grid:{
              display:false
            },
            stacked: true,
          },
          y: {
            ticks: {
              callback: (value, index, values) => {
                return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
              },
            },
            stacked: true,
            suggestedMin: 0,
            suggestedMax: parseInt(ctx.canvas.dataset.legendscale, 10) + (parseInt(ctx.canvas.dataset.legendscale, 10) / 3),
          }
        }
      },
      });
  }
};

export { initAllInvestmentChart };