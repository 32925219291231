import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "publicExercise", "publicExerciseDate", "publicExerciseJob",
  "publicExpositionLink", "publicExposition", "publicFunctionForm", "publicRelationForm", "addressForm", "secondinvestorCategory",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "noNifForm1", "btnAddNIF1", "anotherNifForm1", "noNifForm2",
  "btnAddNIF2", "anotherNifForm2", "noNifForm3"]

  connect() {
    this.showPublicFunction();
    this.showAmericanTin();
    this.togglePublicExerciseFields(); // Ensure correct initial state
    this.publicExerciseTarget.addEventListener('change', () => this.togglePublicExerciseFields());
  };

  togglePublicExpositionLink() {
    this.publicExpositionLinkTarget.classList.toggle('d-none', !this.publicExpositionTarget.checked);
  }

  togglePublicExerciseFields() {
    const isChecked = this.publicExerciseTarget.checked;
    this.publicExerciseDateTarget.classList.toggle("d-none", !isChecked);
    this.publicExerciseJobTarget.classList.toggle("d-none", !isChecked);
  }

  // Below is the  method to display the public function form
  showPublicFunction() {
    this.publicFunctionFormTarget.classList.toggle("d-none");
  }

  // Below is the method to display the American Tax Identification Number Form
  showAmericanTin() {
    this.americanTinFormTarget.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm1() {
    this.noNifForm1Target.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm2() {
    this.noNifForm2Target.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm3() {
    this.noNifForm3Target.classList.toggle("d-none");
  }

  // Below is the method to add another NIF
  showAnotherNifForm1() {
    this.btnAddNIF1Target.classList.add("d-none");
    this.anotherNifForm1Target.classList.remove("d-none");
  }

  // Below is the method to add another NIF
  showAnotherNifForm2() {
    this.btnAddNIF2Target.classList.add("d-none");
    this.anotherNifForm2Target.classList.remove("d-none");
  }
}
