import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form4", "autreSituationPro", "autreProfSituationForm", "autreFormSituationPro", "professionalSituation", "netWorthSource", "autreFormNetWorth", "form5", "publicFunctionForm", 'publicRelationForm']

  connect() {
    this.checkAutreCheckboxOnLoad();
    this.checkAutreCheckboxOnLoad1()
    this.checkAllCheckboxes();
    this.checkAllCheckboxes1();
    // Below is the method to display the input for "Autre" Form for "Situation Professionnelle"
    // in case the user chooses "Autre"
    const autreCheckBoxProfessionalSituation = this.professionalSituationTargets.at(-1)
    autreCheckBoxProfessionalSituation.onclick = (event) => {
      if (autreCheckBoxProfessionalSituation.checked) {
        this.autreFormSituationProTarget.classList.remove('d-none');
      } else {
        this.autreFormSituationProTarget.classList.add('d-none');
      }
    };

    // Below is the method to display the input for "Autre" Form for "Source du Patrimoine"
    // in case the user chooses "Autre"
    const autreCheckBoxNetWorth = this.netWorthSourceTargets.at(-1)
    autreCheckBoxNetWorth.onclick = (event) => {
      if (autreCheckBoxNetWorth.checked) {
        this.autreFormNetWorthTarget.classList.remove('d-none');
      } else {
        this.autreFormNetWorthTarget.classList.add('d-none');
      }
    };
  }

  showPublicFunction(event) {
    if (event.target.checked) {
      this.publicFunctionFormTarget.classList.remove('d-none');
    } else {
      this.publicFunctionFormTarget.classList.add('d-none');
    }
  }

  // Below is the method to display the public relation form
  showPublicRelation(event) {
    if (event.target.checked) {
      this.publicRelationFormTarget.classList.remove('d-none');
    } else {
      this.publicRelationFormTarget.classList.add('d-none');
    }
  }
  
  checkAutreCheckboxOnLoad() {
    const autreCheckBoxNetWorth = this.netWorthSourceTargets.at(-1);
    if (autreCheckBoxNetWorth.checked) {
      this.autreFormNetWorthTarget.classList.remove('d-none');
    } else {
      this.autreFormNetWorthTarget.classList.add('d-none');
    }
  }

  checkAutreCheckboxOnLoad1() {
    const autreCheckBoxSituationPro = this.professionalSituationTargets.at(-1);
    if (autreCheckBoxSituationPro.checked) {
      this.autreFormSituationProTarget.classList.remove('d-none');
    } else {
      this.autreFormSituationProTarget.classList.add('d-none');
    }
  }
  
  checkAllCheckboxes() {
    const netWorthSourceDiv = document.getElementById('net-worth-source');
    const netWorthSource = netWorthSourceDiv.getAttribute('data-net-worth-source');
    if (netWorthSource) {
      const netWorthSourceValues = netWorthSource.split(', ');
      this.netWorthSourceTargets.forEach((checkbox) => {
        if (netWorthSourceValues.includes(checkbox.value)) {
          checkbox.checked = true;
        }
      });
    }
  }

  checkAllCheckboxes1() {
    const professionalSituationDiv = document.getElementById('professional-situation');
    const professionalSituation = professionalSituationDiv.getAttribute('data-professional-situation');
    if (professionalSituation) {
      const professionalSituationValues = professionalSituation.split(', ');
      this.professionalSituationTargets.forEach((checkbox) => {
        if (professionalSituationValues.includes(checkbox.value)) {
          checkbox.checked = true;
        }
      });
    }
  }
}