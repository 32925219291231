// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "chevronDown1", "chevronDown2", "chevronDown3", "chevronLeft1", "chevronLeft2",  "chevronLeft3",
    "Div1", "Div2", "Div3", "titleDiv3" ]


  connect() {
    // console.log("InvestmentsJSController is Working...");
  }

  showDiv1() {
    this.chevronDown1Target.classList.toggle("d-none");
    this.chevronLeft1Target.classList.toggle("d-none");
    this.Div1Target.classList.toggle("d-none");
  }
  showDiv2() {
    this.chevronDown2Target.classList.toggle("d-none");
    this.chevronLeft2Target.classList.toggle("d-none");
    this.Div2Target.classList.toggle("d-none");
  }
  showDiv3() {
    this.chevronDown3Target.classList.toggle("d-none");
    this.chevronLeft3Target.classList.toggle("d-none");
    this.Div3Target.classList.toggle("d-none");
    this.titleDiv3Target.classList.toggle('document-element-fund-last');
    this.titleDiv3Target.classList.toggle('document-element-fund');
  }
}
