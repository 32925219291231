import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form2Image", "form8Title", "polskaRadio", "euroRadio", "fundspage", "investorTypePage",
  "form1", "form2", "form3", "form4", "form5", "form6", "form7", "form8", "form9", "form10", "form11",
  "publicFunctionForm", "publicRelationForm", "addressForm", "secondinvestorCategory",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "autreSituationPro", "autreFormSituationPro", "netWorthSource", "autreFormNetWorth",
  "investSourceForm", "autreFormInvestSource", "physiqueRadio", "moraleRadio", "mform2","postalAddressForm",
  "profSituation", "autreProfSituationForm", "profEstablishment", "autreProfEstablishmentForm", "autreSourceForm",
  "financialAuthorityRadioYes", "authorityForm", "financialProtectionYesBtn", "financialProtectionForm",
  "entityTypeSelect", "autreEntityTypeForm", "entiteActivityForm", "activityDescriptionForm", "fundingForm",
  "page11MoraleTitle", "page11MoraleSubTitle", "page11MoraleSubTitle2", "futureInvestmentsForm", "autreFutureInvestmentsForm",
  "secondinvestorCategoryMoral", "secondinvestorCategoryradiosMoral", "secondinvestorCategoryradio1Moral",
  "secondinvestorCategoryradio2Moral", "secondinvestorCategoryradio3Moral", "americanFormMoral",
  "noGIINform", "GIINform", "noGIINbtn", "GIINbtn", "financialInstitutionform", "GIINsponsor", "NIFbtn", "NIFform", "noNIFbtn", "noNIFform",
  "Regbtn", "regForm", "tierceForm", "additionalFieldsContainer" ]
  static values = { index: Number }
  connect() {
    this.indexValue = 0; // Initialize index
  }

  // Below is the method to display the American Tax Identification Number Form
  showAmericanTin() {
    this.americanTinFormTarget.classList.toggle("d-none");
  }

  // Below is the method to display the address form in case the user clicks on I have multiple addresses - Physical Form
  showAddressForm() {
    this.addressFormTarget.classList.toggle("d-none");
  }

  // Below is the method to enable choosing from the options of 2nd Category of Investors
  allowSecondCategoryClick() {
    if (this.secondinvestorCategoryTarget.checked) {
      this.secondinvestorCategoryradio1Target.disabled = false;
      this.secondinvestorCategoryradio2Target.disabled = false;
      this.secondinvestorCategoryradio3Target.disabled = false;
    } else {
      this.secondinvestorCategoryradio1Target.checked = false;
      this.secondinvestorCategoryradio2Target.checked = false;
      this.secondinvestorCategoryradio3Target.checked = false;
      this.secondinvestorCategoryradio1Target.disabled = true;
      this.secondinvestorCategoryradio2Target.disabled = true;
      this.secondinvestorCategoryradio3Target.disabled = true;
    }
  }

  allowSecondCategoryClickMoral() {
    if (this.secondinvestorCategoryMoralTarget.checked) {
      this.secondinvestorCategoryradio1MoralTarget.disabled = false;
      this.secondinvestorCategoryradio2MoralTarget.disabled = false;
      this.secondinvestorCategoryradio3MoralTarget.disabled = false;
    } else {
      this.secondinvestorCategoryradio1MoralTarget.checked = false;
      this.secondinvestorCategoryradio2MoralTarget.checked = false;
      this.secondinvestorCategoryradio3MoralTarget.checked = false;
      this.secondinvestorCategoryradio1MoralTarget.disabled = true;
      this.secondinvestorCategoryradio2MoralTarget.disabled = true;
      this.secondinvestorCategoryradio3MoralTarget.disabled = true;
    }
  }

  // Below is the method to display the address form in case the user clicks on I have multiple addresses - Moral Form
  showAddress() {
    this.postalAddressFormTarget.classList.toggle("d-none");
  }

  showFinancialAuthorityForm() {
    if (this.financialAuthorityRadioYesTarget.checked) {
      this.authorityFormTarget.classList.remove("d-none");
    } else {
      this.authorityFormTarget.classList.add("d-none");
    };
  }

  showFinancialProtectionForm() {
    if (this.financialProtectionYesBtnTarget.checked) {
      this.financialProtectionFormTarget.classList.remove("d-none");
    } else {
      this.financialProtectionFormTarget.classList.add("d-none");
    };
  }

  showAutreFutureInvestmentsForm() {
    if (this.futureInvestmentsFormTarget.value === "Autre") {
      this.autreFutureInvestmentsFormTarget.classList.remove("d-none");
    } else {
      this.autreFutureInvestmentsFormTarget.classList.add("d-none");
    }
  }

  showAmericanFormMoral() {
    this.americanFormMoralTarget.classList.toggle("d-none");
  }

  showGIIN() {
    if (this.GIINbtnTarget.checked === true) {
      this.noGIINformTarget.classList.add("d-none");
      this.GIINformTarget.classList.remove("d-none");
      this.noGIINbtnTarget.checked = false;
    } else {
      this.GIINformTarget.classList.add("d-none");
    }
  }

  shownoGIIN() {
    if (this.noGIINbtnTarget.checked === true) {
      this.GIINformTarget.classList.add("d-none");
      this.noGIINformTarget.classList.remove("d-none");
      this.GIINbtnTarget.checked = false;
    } else {
      this.noGIINformTarget.classList.add("d-none");
    }
  }

  hideGIINsponsor() {
    this.GIINsponsorTarget.classList.add("d-none");
  }

  showGIINsponsor() {
    this.GIINsponsorTarget.classList.remove("d-none");
  }

  showNIF() {
    if (this.NIFbtnTarget.checked === true) {
      this.NIFformTarget.classList.remove("d-none");
      this.noNIFformTarget.classList.add("d-none");
      this.noNIFbtnTarget.checked = false;
    } else {
      this.NIFformTarget.classList.add("d-none");
    }
  }

  shownoNIF() {
    if (this.noNIFbtnTarget.checked === true) {
      this.noNIFformTarget.classList.remove("d-none");
      this.NIFformTarget.classList.add("d-none");
      this.NIFbtnTarget.checked = false;
    } else {
      this.noNIFformTarget.classList.add("d-none");
    }
  }
}
