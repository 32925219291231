import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["company-modal"]

  // blur background on click on the add monthly payment dividend button
  blur() {
    document.getElementById("body").style.filter = "blur(2px)";
  }

  // hide modal when clicing on the 'X'
  hideModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
    document.getElementById("body").style.filter = "";
  }

  // hide modal on successful form submission
  submitEnd(event) {
    if (event.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  closeWithKeyboard(event) {
    if (event.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  closeBackground(event) {
    if (event && this.modalTarget.contains(event.target)) {
      return
    }
    this.hideModal()
  }
}
