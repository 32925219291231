import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "handswipe" ]

  connect() {
    const handswipe = document.getElementById('handswipe-partner')
    const partnerTable = document.getElementById('partner-table')
    partnerTable.onmousewheel = function() {
      if (this.getBoundingClientRect()["x"] < -100) {
        handswipe.classList.add("d-none");
      } else {
        handswipe.classList.remove("d-none");
      }
    }
  }

}
