import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
  "publicFunctionForm", "publicRelationForm", "addressForm", "secondinvestorCategory", "secondinvestorCategoryradios",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "autreSituationPro", "autreFormSituationPro", "netWorthSource", "autreFormNetWorth",
  "noNifForm1", "btnAddNIF1", "anotherNifForm1", "noNifForm2", "btnAddNIF2", "anotherNifForm2", "noNifForm3"
  ]

  connect() {
    this.showPublicFunction();
    this.showPublicRelation();
    this.showAmericanTin();
  };

  // Below is the method to display the public function form
  showPublicFunction() {
    this.publicFunctionFormTarget.classList.toggle("d-none");
  }

  // Below is the method to display the public relation form
  showPublicRelation() {
    this.publicRelationFormTarget.classList.toggle("d-none");
  }

  // Below is the method to enable choosing from the options of 2nd Category of Investors
  allowSecondCategoryClick() {
    if (this.secondinvestorCategoryTarget.checked) {
      this.secondinvestorCategoryradio1Target.disabled = false;
      this.secondinvestorCategoryradio2Target.disabled = false;
      this.secondinvestorCategoryradio3Target.disabled = false;
    } else {
      this.secondinvestorCategoryradio1Target.checked = false;
      this.secondinvestorCategoryradio2Target.checked = false;
      this.secondinvestorCategoryradio3Target.checked = false;
      this.secondinvestorCategoryradio1Target.disabled = true;
      this.secondinvestorCategoryradio2Target.disabled = true;
      this.secondinvestorCategoryradio3Target.disabled = true;
    }
  }

  // Below is the method to display the American Tax Identification Number Form
  showAmericanTin() {
    this.americanTinFormTarget.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm1() {
    this.noNifForm1Target.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm2() {
    this.noNifForm2Target.classList.toggle("d-none");
  }

  // Below is the method to display the input to explain the abscende of a NIF
  showNoNifForm3() {
    this.noNifForm3Target.classList.toggle("d-none");
  }

  // Below is the method to add another NIF
  showAnotherNifForm1() {
    this.btnAddNIF1Target.classList.add("d-none");
    this.anotherNifForm1Target.classList.remove("d-none");
  }


  // Below is the method to add another NIF
  showAnotherNifForm2() {
  this.btnAddNIF2Target.classList.add("d-none");
  this.anotherNifForm2Target.classList.remove("d-none");
}
}
