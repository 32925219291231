import Chart from 'chart.js/auto';

const legend = (investment_amount) => {
  if (investment_amount < 50000) {
    return 25000
  } else if (investment_amount > 50000 && investment_amount < 100000) {
    return 35000
  } else if (investment_amount > 100000 && investment_amount < 300000) {
    return 50000
  } else if (investment_amount > 300000 && investment_amount < 700000) {
    return 60000
  }
}

const initChartInvestmentValue = () => {
if (document.getElementById('InvestmentValue')) {
  var ctx = document.getElementById('InvestmentValue').getContext('2d');
        var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            radius: 1,
            label: "Valeur liquidative de rachat",
            data: JSON.parse(ctx.canvas.dataset.data),
            barThickness: 32,
            backgroundColor: 'rgb(29, 157, 217)',
            borderColor: 'rgb(29, 157, 217)',
            borderWidth: 3,
          },
          {
            radius: 1,
            label: "Valeur liquidative de souscription",
            data: JSON.parse(ctx.canvas.dataset.data1),
            barThickness: 32,
            backgroundColor: 'rgb(183, 141, 11)',
            borderColor: 'rgb(183, 141, 11)',
            borderWidth: 3
          }]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                padding: 30,
                  // This more specific font property overrides the global property
                  font: {
                      family: "montserrat",
                      size: 10,
                  }
              }
            }
        },
          scales: {
            x: {
              grid:{
                display:false
              }
            },
            y: {
              ticks: {
                callback: (value, index, values) => {
                  return new Intl.NumberFormat('fr-EU', { style: 'currency', currency: 'EUR'}).format(value);
                },
                stepSize: 20000,
              },
              suggestedMin: parseInt(ctx.canvas.dataset.inv, 10) - legend(parseInt(ctx.canvas.dataset.inv, 10)),
              suggestedMax: parseInt(ctx.canvas.dataset.inv, 10) + (legend(parseInt(ctx.canvas.dataset.inv, 10)) / 2),
            },
          }
        },
        });
}

if (document.getElementById('InvestmentValueMobile')) {
  var ctx = document.getElementById('InvestmentValueMobile').getContext('2d');
    var myChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: JSON.parse(ctx.canvas.dataset.labels),
      datasets: [{
        radius: 1,
        label: "VL rachat",
        data: JSON.parse(ctx.canvas.dataset.data),
        barThickness: 32,
        backgroundColor: 'rgb(29, 157, 217)',
        borderColor: 'rgb(29, 157, 217)',
        borderWidth: 3,
      },
      {
        radius: 1,
        label: "VL souscription",
        data: JSON.parse(ctx.canvas.dataset.data1),
        barThickness: 32,
        backgroundColor: 'rgb(183, 141, 11)',
        borderColor: 'rgb(183, 141, 11)',
        borderWidth: 3
      }]
    },
    options: {
      plugins: {
        legend: {
            labels: {
                // This more specific font property overrides the global property
                font: {
                    family: "montserrat",
                    size: 10,
                }
            }
        }
    },
      scales: {
        x: {
          grid:{
            display:false
          }
        },
        y: {
          suggestedMin: parseInt(ctx.canvas.dataset.inv, 10) - legend(parseInt(ctx.canvas.dataset.inv, 10)),
          suggestedMax: parseInt(ctx.canvas.dataset.inv, 10) + (legend(parseInt(ctx.canvas.dataset.inv, 10)) / 2),
          ticks: {
            stepSize: 20000,
          }
        },
      }
    },
    });
}
};



export { initChartInvestmentValue };
