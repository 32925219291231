import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form2Image", "form8Title", "polskaRadio", "euroRadio", "fundspage", "investorTypePage",
  "form1", "form2", "form3", "form4", "form5", "form6", "form7", "form8", "form9", "form10", "form11",
  "publicFunctionForm", "publicRelationForm", "addressForm", "secondinvestorCategory",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "autreSituationPro", "autreFormSituationPro", "netWorthSource", "autreFormNetWorth",
  "investSourceForm", "autreFormInvestSource", "physiqueRadio", "moraleRadio", "mform2","postalAddressForm",
  "profSituation", "autreProfSituationForm", "profEstablishment", "autreProfEstablishmentForm", "autreSourceForm",
  "financialAuthorityRadioYes", "authorityForm", "financialProtectionYesBtn", "financialProtectionForm",
  "entityTypeSelect", "autreEntityTypeForm", "entiteActivityForm", "activityDescriptionForm", "fundingForm",
  "page11MoraleTitle", "page11MoraleSubTitle", "page11MoraleSubTitle2", "futureInvestmentsForm", "autreFutureInvestmentsForm",
  "secondinvestorCategoryMoral", "secondinvestorCategoryradiosMoral", "secondinvestorCategoryradio1Moral",
  "secondinvestorCategoryradio2Moral", "secondinvestorCategoryradio3Moral", "americanFormMoral",
  "noGIINform", "GIINform", "noGIINbtn", "GIINbtn", "GIINsponsor", "NIFbtn", "NIFform", "noNIFbtn", "noNIFform",
  "Regbtn", "regForm", "tierceForm", "additionalFieldsContainer"]
  static values = { index: Number }
  connect() {
    this.indexValue = 0; // Initialize index
    // // this.showAmericanTin();
    // this.showFinancialAuthorityForm();
    //Below is the listener that listens on funds buttons to change the form accordingly
    // const btns = [this.polskaRadioTarget, this.euroRadioTarget]
    // btns.forEach(btn => btn.addEventListener('click', (event) => {
    //     if (window.event.srcElement === this.polskaRadioTarget) {
    //       this.form8TitleTarget.innerHTML = "VOTRE INVESTISSEMENT DANS POLSKA"
    //       this.form2ImageTarget.src = 'https://iili.io/DrG0X4.png';
    //       this.page11MoraleTitleTarget.innerHTML = "VOTRE INVESTISSEMENT DANS POLSKA"
    //       this.page11MoraleSubTitleTarget.innerHTML = "Investissements futurs dans POLSKA :"
    //       this.page11MoraleSubTitle2Target.innerHTML = "Montant total de mes investissements actuels et futurs dans POLSKA"
    //       this.euroRadioTarget.checked = false;
    //     } else if (window.event.srcElement === this.euroRadioTarget) {
    //       this.form8TitleTarget.innerHTML = "VOTRE INVESTISSEMENT DANS MNK EUROPE +"
    //       this.form2ImageTarget.src = 'https://i.postimg.cc/6py1fHyJ/euro.png';
    //       this.page11MoraleTitleTarget.innerHTML = "VOTRE INVESTISSEMENT DANS MNK EUROPE +"
    //       this.page11MoraleSubTitleTarget.innerHTML = "Investissements futurs dans MNK EUROPE + :"
    //       this.page11MoraleSubTitle2Target.innerHTML = "Montant total de mes investissements actuels et futurs dans MNK EUROPE +"
    //       this.polskaRadioTarget.checked = false;
    //     };
    //   })
    // );

    // // Below is the method to display the input for "Autre" Form for "Source d'investissements"
    // // in case the user chooses "Autre"
    // const autreCheckBoxInvestSource = this.investSourceFormTargets.at(-1)
    // autreCheckBoxInvestSource.onclick = (event) => {
    //   if (autreCheckBoxInvestSource.checked) {
    //     this.autreFormInvestSourceTarget.classList.remove('d-none');
    //   } else {
    //     this.autreFormInvestSourceTarget.classList.add('d-none');
    //   }
    // };
  }

  // Below is the method to display the address form in case the user clicks on I have multiple addresses - Physical Form
  showAddressForm() {
    // Assuming there's a checkbox with a specific ID or class that you can select
    const checkbox = document.querySelector('#souscription_resi_address_as_fisca_address'); // Adjust the selector as needed
    if (checkbox.checked) {
      this.addressFormTarget.classList.add('d-none');
    } else {
      this.addressFormTarget.classList.remove('d-none');
    }
  }

  // Below is the method to display the address form in case the user clicks on I have multiple addresses - Moral Form
  showAddress() {
    this.postalAddressFormTarget.classList.toggle("d-none");
  }

  showAutreProfSituationForm() {
    if (this.profSituationTarget.value === "Autre") {
      this.autreProfSituationFormTarget.classList.remove("d-none");
    } else {
      this.autreProfSituationFormTarget.classList.add("d-none");
    }
  }

  showAutreProfEstablishmentForm() {
    if (this.profEstablishmentTarget.value === "Autre") {
      this.autreProfEstablishmentFormTarget.classList.remove("d-none");
    } else {
      this.autreProfEstablishmentFormTarget.classList.add("d-none");
    }
  }

  showAutreSourceForm() {
    this.autreSourceFormTarget.classList.toggle("d-none");
  }

  showFinancialAuthorityForm() {
    if (this.financialAuthorityRadioYesTarget.checked) {
      this.authorityFormTarget.classList.remove("d-none");
    } else {
      this.authorityFormTarget.classList.add("d-none");
    };
  }

  showFinancialProtectionForm() {
    if (this.financialProtectionYesBtnTarget.checked) {
      this.financialProtectionFormTarget.classList.remove("d-none");
    } else {
      this.financialProtectionFormTarget.classList.add("d-none");
    };
  }

  showAutreEntityTypeForm() {
    if (this.entityTypeSelectTarget.value === "Autre") {
      this.autreEntityTypeFormTarget.classList.remove("d-none");
    } else {
      this.autreEntityTypeFormTarget.classList.add("d-none");
    }
  }

  showEntiteActivityForm() {
    this.entiteActivityFormTarget.classList.toggle("d-none");
  }

  showActivityDescriptionForm() {
    this.activityDescriptionFormTarget.classList.toggle("d-none");
  }

  showFundingForm() {
    this.fundingFormTarget.classList.toggle("d-none");
  }

  showAutreFutureInvestmentsForm() {
    if (this.futureInvestmentsFormTarget.value === "Autre") {
      this.autreFutureInvestmentsFormTarget.classList.remove("d-none");
    } else {
      this.autreFutureInvestmentsFormTarget.classList.add("d-none");
    }
  }

  showAmericanFormMoral() {
    this.americanFormMoralTarget.classList.toggle("d-none");
  }

  showGIIN() {
    if (this.GIINbtnTarget.checked === true) {
      this.noGIINformTarget.classList.add("d-none");
      this.GIINformTarget.classList.remove("d-none");
      this.noGIINbtnTarget.checked = false;
    } else {
      this.GIINformTarget.classList.add("d-none");
    }
  }

  shownoGIIN() {
    if (this.noGIINbtnTarget.checked === true) {
      this.GIINformTarget.classList.add("d-none");
      this.noGIINformTarget.classList.remove("d-none");
      this.GIINbtnTarget.checked = false;
    } else {
      this.noGIINformTarget.classList.add("d-none");
    }
  }

  hideGIINsponsor() {
    this.GIINsponsorTarget.classList.add("d-none");
  }

  showGIINsponsor() {
    this.GIINsponsorTarget.classList.remove("d-none");
  }

  showNIF() {
    if (this.NIFbtnTarget.checked === true) {
      this.NIFformTarget.classList.remove("d-none");
      this.noNIFformTarget.classList.add("d-none");
      this.noNIFbtnTarget.checked = false;
    } else {
      this.NIFformTarget.classList.add("d-none");
    }
  }

  shownoNIF() {
    if (this.noNIFbtnTarget.checked === true) {
      this.noNIFformTarget.classList.remove("d-none");
      this.NIFformTarget.classList.add("d-none");
      this.NIFbtnTarget.checked = false;
    } else {
      this.noNIFformTarget.classList.add("d-none");
    }
  }
}
