import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
  "addressForm", "secondinvestorCategory",
  "secondinvestorCategoryradio1", "secondinvestorCategoryradio2", "secondinvestorCategoryradio3",
  "americanTinForm", "autreSituationPro", "autreProfSituationForm", "autreFormSituationPro", "professionalSituation", "netWorthSource", "autreFormNetWorth",
  "noNifForm1", "btnAddNIF1", "anotherNifForm1", "noNifForm2", "btnAddNIF2", "anotherNifForm2", "noNifForm3"
  ]

  connect() {
    this.showAddressForm();
    this.checkAutreCheckboxOnLoad();
    this.checkAutreCheckboxOnLoad1()
    this.checkAllCheckboxes();
    this.checkAllCheckboxes1();
    // in case the user chooses "Autre"
    const autreCheckBoxNetWorth = this.netWorthSourceTargets.at(-1)
    autreCheckBoxNetWorth.onclick = (event) => {
      if (autreCheckBoxNetWorth.checked) {
        this.autreFormNetWorthTarget.classList.remove('d-none');
      } else {
        this.autreFormNetWorthTarget.classList.add('d-none');
      }
    };

    const autreCheckBoxProfessionalSituation = this.professionalSituationTargets.at(-1)
    autreCheckBoxProfessionalSituation.onclick = (event) => {
      if (autreCheckBoxProfessionalSituation.checked) {
        this.autreFormSituationProTarget.classList.remove('d-none');
      } else {
        this.autreFormSituationProTarget.classList.add('d-none');
      }
    };
  }

    // Below is the method to display the address form in case the user clicks on I have multiple addresses - Physical Form
    showAddressForm() {
      this.addressFormTarget.classList.toggle("d-none");
    }

    checkAutreCheckboxOnLoad() {
      const autreCheckBoxNetWorth = this.netWorthSourceTargets.at(-1);
      if (autreCheckBoxNetWorth.checked) {
        this.autreFormNetWorthTarget.classList.remove('d-none');
      } else {
        this.autreFormNetWorthTarget.classList.add('d-none');
      }
    }

    checkAutreCheckboxOnLoad1() {
      const autreCheckBoxSituationPro = this.professionalSituationTargets.at(-1);
      if (autreCheckBoxSituationPro.checked) {
        this.autreFormSituationProTarget.classList.remove('d-none');
      } else {
        this.autreFormSituationProTarget.classList.add('d-none');
      }
    }

    checkAllCheckboxes() {
      const netWorthSourceDiv = document.getElementById('net-worth-source');
      const netWorthSource = netWorthSourceDiv.getAttribute('data-net-worth-source');
      if (netWorthSource) {
        const netWorthSourceValues = netWorthSource.split(', ');
        this.netWorthSourceTargets.forEach((checkbox) => {
          if (netWorthSourceValues.includes(checkbox.value)) {
            checkbox.checked = true;
          }
        });
      }
    }

    checkAllCheckboxes1() {
      const professionalSituationDiv = document.getElementById('professional-situation');
      const professionalSituation = professionalSituationDiv.getAttribute('data-professional-situation');
      if (professionalSituation) {
        const professionalSituationValues = professionalSituation.split(', ');
        this.professionalSituationTargets.forEach((checkbox) => {
          if (professionalSituationValues.includes(checkbox.value)) {
            checkbox.checked = true;
          }
        });
      }
    }
}