import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

let positionChart = 'right';
if (window.matchMedia("(max-width: 767px)").matches)
{
  positionChart = 'bottom';
}

const initAssetChart = () => {
  if (document.getElementById('asset-pie-chart')) {
    var ctx = document.getElementById('asset-pie-chart').getContext('2d');
          var myChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: JSON.parse(ctx.canvas.dataset.labels),
            datasets: [{
              data: JSON.parse(ctx.canvas.dataset.data),
              barThickness: 32,
              backgroundColor: ['#1d9dd9', '#00232A', '#76A390', '#BBCC9A', '#B78D0C'],
              borderWidth: 1
            }]
          },
          options: {
            plugins: {
              legend: {
                position: positionChart,
                fullSize: true,
                labels: {
                  font: {
                    size: 10,
                    family: "Montserrat"
                  }
                }
              },
              datalabels: {
                color: '#ffffff',
                formatter: (value, context) => {
                  const datapoints = context.chart.data.datasets[0].data
                  function totalSum(total, datapoint) {
                    return total + datapoint;
                  }

                  const totalvalue = datapoints.reduce(totalSum, 0);
                  const percentageValue = (value / totalvalue * 100);
                    return `${(percentageValue).toFixed(0)}%`;
                },
                font: {
                  weight: 'bold',
                }
              }
            }
          },
          plugins: [ChartDataLabels]
          });
  }

  if (document.getElementById('asset-pie-chart-countries')) {
    var ctx1 = document.getElementById('asset-pie-chart-countries').getContext('2d');
      var myChart2 = new Chart(ctx1, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(ctx1.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx1.canvas.dataset.data),
          barThickness: 32,
          backgroundColor: ['#1d9dd9', '#76A390', '#00232A', '#BBCC9A', '#B78D0C'],
          borderWidth: 1,
        }]
      },
      options: {
        plugins: {
          legend: {
            position: positionChart,
            fullSize: true,
                labels: {
                  font: {
                    size: 10,
                    family: "Montserrat"
                  }
                }
              },
              tooltip: {
                enabled: true
              },
          datalabels: {
            color: '#ffffff',
            formatter: (value, context) => {
                const datapoints = context.chart.data.datasets[0].data
                function totalSum(total, datapoint) {
                  return total + datapoint;
                }

                const totalvalue = datapoints.reduce(totalSum, 0);
                const percentageValue = (value / totalvalue * 100);
                return `${(percentageValue).toFixed(0)}%`;
            },
            font: {
              weight: 'bold',
            }
          }
        }
      },
      plugins: [ChartDataLabels]
      });
  }
};

export { initAssetChart };
